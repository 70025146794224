import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c717383a = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _4a8ce8a0 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _6d6fe979 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _80c8f000 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _5b57872f = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _3d12a2ff = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _67805f9e = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _ff657588 = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _42fede16 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _771353d8 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _5bef4f5e = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _5fa95047 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _cda74c88 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _7c2c09e8 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _895f45e0 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _c6b20ea2 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _31b9f9bd = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _556df4f6 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _6892ea50 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _b18146e2 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _67b5d7bd = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _d9408f9c = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _a787b19a = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _eab06526 = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _5c0e5170 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _3a12daad = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _219d2768 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _d46f2106 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _0ba7abf2 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _0cd03af0 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _4c0a9e5f = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _5c1f102e = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _8141f876 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _b4789972 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _38d021e8 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _7adc4116 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _0ab9b986 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _a6aee4c0 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _12722b15 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _7491936c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _c717383a,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _4a8ce8a0,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _6d6fe979,
    name: "help"
  }, {
    path: "/verify-email",
    component: _80c8f000,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _5b57872f,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _3d12a2ff,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _67805f9e,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _ff657588,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _42fede16,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _771353d8,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _5bef4f5e,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _5fa95047,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _cda74c88,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _7c2c09e8,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _895f45e0,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _c6b20ea2,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _31b9f9bd,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _556df4f6,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _6892ea50,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _b18146e2,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _67b5d7bd,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _d9408f9c,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _a787b19a,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _eab06526,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _5c0e5170,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _3a12daad,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _219d2768,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _d46f2106,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _0ba7abf2,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _0cd03af0,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _4c0a9e5f,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _5c1f102e,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _8141f876,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _b4789972,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _38d021e8,
    name: "onboarding-suez-info"
  }, {
    path: "/personal/account",
    component: _7adc4116,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _0ab9b986,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _a6aee4c0,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _12722b15,
    name: "blog-slug"
  }, {
    path: "/",
    component: _7491936c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
